import React from "react";
import "../../Components/NavBar/NavBar.css";
import CleeQLogo from "../CleeQLogo";
import NavBarList from "./NavBarList";
import SignUpAndLogin from "../SignUpAndLogin/SignUpAndLogin";
import NavBarRes from "./NavBarRes";

function NavBar() {
  return (
    <>
      <div className="container NavBarContainer p-0">
        <div>
          <CleeQLogo />
        </div>
        <div className="NavBarListDetails">
          <NavBarList />
        </div>
        <div className="SignUpAndLoginContianer22">
          <SignUpAndLogin />
        </div>
        <div className="SignUpAndLoginTray">
          <NavBarRes />
        </div>
      </div>
    </>
  );
}

export default NavBar;
