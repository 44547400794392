import React from 'react'

function SignUpAndLogin() {
  return (
        <div className="SignUpAndLoginContianer">
        <div>
          <div>
            <button className="SignIn-LogIn-Btn">
              <a
                href="https://cleeq24.com/login"
                className="CleeQ__Link"
              >
                Login
              </a>
            </button>{" "}
            {/* <Navbar.Text>
      Signed in as: <a href="#login">Mark Otto</a>
    </Navbar.Text> */}
          </div>
        </div>
        <div>
          {" "}
          <div>
            <button className="SignIn-LogIn-Btn">
              <a
                href="https://clique-v1.vercel.app/Signup"
                className="CleeQ__Link"
              >
                Join the CleeQ for free
              </a>
            </button>{" "}
            {/* <Navbar.Text>
      Signed in as: <a href="#login">Mark Otto</a>
    </Navbar.Text> */}
          </div>
        </div>
      </div>
  )
}

export default SignUpAndLogin